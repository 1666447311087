@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use "variables" as var;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* General styling that applies to many pages */


.spb-error {
  background-color: var.$spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

/**
  Checkbox labels do not line break and have to be here ... 2019-08-08 /Daniel
 */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

/* Fix the height of input fields? */
//.mat-input-element {
//  line-height: 10px !important;
//}
input {
  line-height: 19px !important;
}

h1,
h2,
h3 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

h4 {
  margin-bottom: 7px !important;
  margin-top: 20px !important;
}

a {
  color: #232323;
}

